<template>
  <div>
    <section id="account-section" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <div class="card-title">
          <h3>DUKE Account</h3>


          <!-- general -->
          <div class="appointment-grid">
            <div class="jtitle">
              <h3>User Profile</h3>
            </div>

            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">ID</p>
                <p class="p1-platform">{{ $C.getTrimmedId(userId) }}</p>
              </div>
            </div>

            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Type</p>
                <p class="p1-platform">{{ userType }}</p>
              </div>
            </div>

            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Email</p>
                <p class="p1-platform">{{ user.email }}</p>
              </div>
            </div>

            <!-- TODO: needs to be handled via backend functions -->
            <!-- <div class="btn-container-center">
              <div class="jbtn jbtn-grey" @click="showChangeEmailDialog">Change Email</div>
            </div> -->
          </div>

          <!-- separator hr -->
          <div class="line"></div>

          <!-- personal info -->
          <div class="jtitle jtitle-w-btn">
            <h3>Personal info</h3>
            <div @click="showDialog" class="p-grey jbtn-text ml-3">
              <span class="fa fa-pencil"></span>
            </div>
          </div>

          <div class="profile-grid-2">
            <!-- name -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Name</p>
                <p class="p1-platform">{{ user.firstName + " " + user.lastName }}</p>
              </div>
            </div>

            <!-- phone -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Phone</p>
                <p class="p1-platform">{{ user.tel }}</p>
              </div>
            </div>

            <!-- city -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Address</p>
                <p class="p1-platform">{{ user.addr }}</p>
              </div>
            </div>

            <!-- city -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">City</p>
                <p class="p1-platform">{{ user.city }}</p>
              </div>
            </div>

            <!-- zip -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Zip Code</p>
                <p class="p1-platform">{{ user.zip }}</p>
              </div>
            </div>

            <!-- country -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Country</p>
                <p class="p1-platform">{{ user.country }}</p>
              </div>
            </div>

            <!-- birth date -->
            <div class="jcard-border" v-if="!userIsPress">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Date of birth</p>
                <p class="p1-platform">{{ user.birthday }}</p>
              </div>
            </div>

            <!-- creation date -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Created date</p>
                <p class="p1-platform">{{ $moment(getFormatDate(user.createdOn)).format("DD-MM-YYYY") }}</p>
              </div>
            </div>
          </div>

          <!-- separator hr -->
          <div class="line"></div>

          <!-- Company -->
          <div v-if="userIsPress">
            <!-- company info -->
            <div class="jtitle jtitle-w-btn">
              <h3>Company info</h3>
              <div @click="showDialog" class="p-grey jbtn-text ml-3">
                <span class="fa fa-pencil"></span>
              </div>
            </div>

            <!-- press id -->
            <div class="jcard-border mb-3">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Press ID</p>
                <p class="p1-platform">{{ company.pressId }}</p>
              </div>
            </div>

            <div class="profile-grid-2">
              <!-- name -->
              <div class="jcard-border">
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Name</p>
                  <p class="p1-platform">{{ company.name }}</p>
                </div>
              </div>

              <!-- phone -->
              <div class="jcard-border">
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Phone</p>
                  <p class="p1-platform">{{ company.phone }}</p>
                </div>
              </div>

              <!-- city -->
              <div class="jcard-border">
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Address</p>
                  <p class="p1-platform">{{ company.addr }}</p>
                </div>
              </div>

              <!-- city -->
              <div class="jcard-border">
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">City</p>
                  <p class="p1-platform">{{ company.city }}</p>
                </div>
              </div>

              <!-- zip -->
              <div class="jcard-border">
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Zip Code</p>
                  <p class="p1-platform">{{ company.zip }}</p>
                </div>
              </div>

              <!-- country -->
              <div class="jcard-border">
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Country</p>
                  <p class="p1-platform">{{ company.country }}</p>
                </div>
              </div>
            </div>

            <!-- separator hr -->
            <div class="line"></div>
          </div>

        </div>

        <!-- ----- edit user profile modal --------------------------------- -->
        <b-modal :hide-header="true" :hide-footer="true" id="edit-user-modal" ref="edit-user-modal" centered
          title="BootstrapVue">
          <div class="jdialog-top">
            <div class="close-container">
              <h3><strong>Edit profile</strong></h3>
              <span @click="$bvModal.hide('edit-user-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
            </div>
            <p><small>Keep your information up to date.</small></p>
          </div>
          <div class="jdialog-main">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="name"> <i class="fal fa-user"></i> First Name </label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validName(editData.firstName) && bluredFirstName
                  }" v-on:blur="bluredFirstName = true" v-model="editData.firstName" />
                  <div class="invalid-feedback">Please tell us your name.</div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="name"> <i class="fal fa-user"></i> Last Name </label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validName(editData.lastName) && bluredLastName
                  }" v-on:blur="bluredLastName = true" v-model="editData.lastName" />
                  <div class="invalid-feedback">Please tell us your name.</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-map-marker-alt"></i> Address</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.addr) && bluredAddress
                  }" v-on:blur="bluredAddress = true" v-model="editData.addr" />
                  <div class="invalid-feedback">Please enter an address.</div>
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-map-marker-alt"></i> City</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.city) && bluredCity
                  }" v-on:blur="bluredCity = true" v-model="editData.city" />
                  <div class="invalid-feedback">Please enter a city.</div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-map-marker-alt"></i> Zip</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.zip) && bluredZip
                  }" v-on:blur="bluredZip = true" v-model="editData.zip" />
                  <div class="invalid-feedback">Please enter a zip code.</div>
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-phone-alt"></i> Phone</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validPhone(editData.tel) && bluredTel
                  }" v-on:blur="bluredTel = true" v-model="editData.tel" />
                  <div class="invalid-feedback">
                    Please enter a phone number.
                  </div>
                </div>
              </div>
            </div>

            <!-- Press ID -->
            <div class="col-md" v-if="userIsPress">
              <div class="form-group">
                <label for="email"><i class="fal fa-phone-alt"></i> Press ID</label>
                <input type="text" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validInput(editDataCompany.pressId) && bluredCompanyPressId
                }" v-on:blur="bluredCompanyPressId = true" v-model="editDataCompany.pressId" />
                <div class="invalid-feedback">
                  Please enter a press.
                </div>
              </div>
            </div>
            <div class="col-md" v-else>
              <div class="form-group">
                <p for="email" class="p4"><i class="fal fa-calendar-alt"></i> Date of Birth</p>
                <input type="date" class="input" placeholder="" v-model="editData.birthday"
                  v-on:blur="bluredBirthday = true" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.birthday) && bluredBirthday
                  }" />
                <div class="invalid-feedback">
                  Please enter a birthday.
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="email"><i class="fal fa-globe-europe"></i> Country</label>
              <b-form-select v-model="editData.country" :options="countriesList"></b-form-select>
            </div>
          </div>

          <!-- Company infos for press -->
          <div v-if="userIsPress" class="jdialog-main">
            <p><small>Company Info</small></p>
            <br />
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="name"> <i class="fal fa-user"></i> Name </label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validName(editDataCompany.name) && bluredCompanyName
                  }" v-on:blur="bluredCompanyName = true" v-model="editDataCompany.name" />
                  <div class="invalid-feedback">Please tell us the name.</div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-phone-alt"></i> Phone</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validPhone(editDataCompany.phone) && bluredCompanyPhone
                  }" v-on:blur="bluredCompanyPhone = true" v-model="editDataCompany.phone" />
                  <div class="invalid-feedback">
                    Please enter a phone number.
                  </div>
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-map-marker-alt"></i> Address</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.addr) && bluredCompanyAddress
                  }" v-on:blur="bluredCompanyAddress = true" v-model="editDataCompany.addr" />
                  <div class="invalid-feedback">Please enter an address.</div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-map-marker-alt"></i> Zip</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editDataCompany.zip) && bluredCompanyZip
                  }" v-on:blur="bluredCompanyZip = true" v-model="editDataCompany.zip" />
                  <div class="invalid-feedback">Please enter a zip code.</div>
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-city"></i> City</label>
                  <input type="text" class="form-control" v-model="editDataCompany.city" />
                  <div class="invalid-feedback">Please enter a city.</div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <label for="email"><i class="fal fa-globe-europe"></i> Country</label>
              <b-form-select v-model="editDataCompany.country" :options="countriesList"></b-form-select>
            </div>
          </div>

          <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
            {{ validationMsg }}
          </b-alert>

          <div class="jdialog-bottom with-cancel">
            <button @click="$bvModal.hide('edit-user-modal')" class="jbtn jbtn-sm jbtn-red">
              <i class="fa fa-times"></i> Cancel
            </button>
            <button @click="updateUserInfo" class="jbtn jbtn-sm"><i class="fa fa-save"></i> Update</button>
          </div>
        </b-modal>


        <!-- -------------- -->
        <!-- OLD STUFF      -->
        <!-- -------------- -->

        <div v-if="!userIsPress">

          <div class="card-title">
            <h3>Watches</h3>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>Id</th>
                <th scope="col">Model</th>
                <th scope="col">Serial</th>
                <th scope="col">Expiry</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(watch, index) in watches" :key="watch.id">
                <td><i class="fa fa-watch fa-2x" style="color: #ca9e81"></i></td>
                <td>{{ watch.id.substring(0, 8) }}</td>
                <td>{{ watchModelNames.get(watch.modelId) }}</td>
                <!-- <td>{{ getModelName(watch) }}</td> -->
                <td>{{ watch.serial }}</td>
                <td>
                  {{ $moment(getFormatDate(watch.warranties[watch.warrantyActive].end)).format("DD-MM-YYYY") }}
                </td>
                <td>
                  <span class="badge badge-success text-uppercase">{{
                    $C.STATUS.WATCH_STRINGS[watch.status]
                  }}</span>
                </td>
                <td>
                  <button class="jbtn jbtn-icon jbtn-blue" @click="openWatch(index)">
                    <i class="fa fa-search"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!watches || watches.length < 1" class="text-center mb-4">
            <i class="fal fa-folder-open"></i> This list is empty.
          </div>
        </div>


        <!-- ----- change email modal --------------------------------- -->
        <b-modal :hide-header="true" :hide-footer="true" id="change-email-modal" ref="change-email-modal" centered
          title="BootstrapVue">
          <div class="jdialog-top">
            <div class="close-container">
              <h3><strong>Change email</strong></h3>
              <span @click="$bvModal.hide('change-email-modal')" class="ml-auto jclose"><i
                  class="fa fa-times "></i></span>
            </div>
            <p>
              <small>In order to change your email, please re-enter your
                password.<br />Note that you will need to use your new email to
                login.</small>
            </p>
          </div>
          <div class="jdialog-main">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="name">
                    <i class="fal fa-lock"></i> Current password
                  </label>
                  <input type="password" v-bind:class="{
                    'form-control': true,
                    'is-invalid':
                      !validPassword(currentPassword) && bluredPassword,
                  }" v-on:blur="bluredPassword = true" v-model="currentPassword" />
                  <div class="invalid-feedback">
                    Please enter your current password.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-envelope"></i> New email</label>
                  <input type="email" v-bind:class="{
                    'form-control': true,
                    'is-invalid':
                      !validEmail(editEmailData.email) && bluredEmail,
                  }" v-on:blur="bluredEmail = true" v-model="editEmailData.email" />
                  <div class="invalid-feedback">
                    Please enter a valid email.
                  </div>
                </div>
              </div>
            </div>

            <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
              {{ validationMsg }}
            </b-alert>
          </div>
          <div class="jdialog-bottom with-cancel">
            <button @click="$bvModal.hide('change-email-modal')" class="jbtn jbtn-sm jbtn-red">
              <i class="fa fa-times"></i> Cancel
            </button>
            <button @click="initUpdateEmail" class="jbtn jbtn-sm">
              <span v-if="isSaving" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
              <span v-else key="button"><i class="fa fa-save"></i> Change</span>
            </button>
          </div>
        </b-modal>

        <!-- TODO: if it is possible: only via backend -->
        <!-- Email Verification -->
        <!-- <h3 class="mb-4 mt-3">Email verification</h3>
        <div class="jrow-settings">
          <div>
            <label><strong><i class="fa fa-envelope"></i> Your email address is</strong></label>
            <span v-if="!emailVerified" class="badge badge-danger ml-2">unverified</span>
            <span v-else class="badge badge-success ml-2">verified</span>
          </div>
          <button class="jbtn jbtn-outline jbtn-xs" @click="sendEmailVerification">
            Verify
          </button>
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
import C from "@/const";
import CL from "@/countries";
import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "UserAccount",
  data() {
    return {
      isLoading: false,
      userId: "",

      //form error alert
      showInputError: false,
      validationMsg: "",

      //form button
      isSaving: false,

      //edit email form
      validChangeEmail: false,
      currentPassword: "",
      bluredPassword: false,
      bluredEmail: false,
      editEmailData: {
        email: "",
      },
      //edit user info form
      valid: false,
      bluredFirstName: false,
      bluredLastName: false,
      bluredAddress: false,
      bluredCity: false,
      bluredBirthday: false,
      bluredZip: false,
      bluredTel: false,
      // validName: false,
      // validAddr: true,
      // validZip: true,

      // edit press info
      bluredCompanyPressId: false,
      bluredCompanyName: false,
      bluredCompanyPhone: false,
      bluredCompanyAddress: false,
      bluredCompanyZip: false,

      editData: {
        firstName: "",
        lastName: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg",
        birthday: "",
        tel: "",
        modifiedOn: serverTimestamp,
      },
      editDataCompany: {
        pressId: "",
        name: "",
        email: "",
        phone: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg"
      },

      countriesList: CL.COUNTRIES,

      // delivery addr options
      deliveryAddrChecked: true,
      // email verification
      emailVerified: false,

      watches: [],
      watchModelNames: new Map(),
    };
  },
  methods: {
    loadData(userId) {
      // TODO: use loading logic like other pages instead

      // load models
      db.collection(C.COLLECTION.WATCH_CATALOG).get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const model = doc.data();
            model.id = doc.id;
            console.log("GOT WATCHMODEL: " + model.id);
            this.watchModelNames.set(model.id, model.name);
          });

          db.collection(C.COLLECTION.WATCHES)
            .where("userId", "==", userId)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const docdata = doc.data();
                docdata.id = doc.id;
                this.watches.push(docdata);
              });
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });
        })
        .catch((error) => {
          console.log("Error getting models: ", error);
        });
    },
    getFormatDate(date) {
      if (!date)
        return null;

      return new Date(date.seconds * 1000);
    },
    openWatch(index) {
      this.$store.commit("setWatch", this.watches[index]);
      this.$router.push({
        name: "Watch",
        params: { id: this.watches[index].id },
      });
    },
    // setUserDoc(data){
    //       this.$store.commit("setUser", doc.data());

    // }
    // startContrat(type) {
    //   this.$store.commit("startAddContrat");
    // },
    showChangeEmailDialog() {
      toast.success("Coming soon");
      // this.$refs["change-email-modal"].show();
    },
    initUpdateEmail() {
      this.isSaving = true;
      if (!this.validateChangeEmail()) {
        this.isSaving = false;
        return;
      }

      //re-reauthenticate user first, than try updating email
      this.reauthenticate();
    },
    updateEmail() {
      var user = auth.currentUser;

      //update firebase auth
      user
        .updateEmail(this.editEmailData.email)
        .then(() => {
          // Update successful.
          //update firebase user doc
          var docRef = db.collection(C.COLLECTION.USERS).doc(user.uid);
          docRef
            .set(this.editEmailData, { merge: true })
            .then(() => {
              //reset data
              this.currentPassword = "";
              this.editEmailData.email = "";
              this.bluredEmail = false;
              this.bluredPassword = false;
              this.isSaving = false;
              //hide modal
              this.$refs["change-email-modal"].hide();
              //show toast
              toast.success("Your email has been udated.");
            })
            .catch((error) => {
              toast.error("Couldn't update email. " + error.message);
              this.isSaving = false;
            });
        })
        .catch((error) => {
          toast.error("Couldn't update email. " + error.message);
          this.isSaving = false;
        });
    },
    showDialog() {
      this.editData.firstName = this.user.firstName;
      this.editData.lastName = this.user.lastName;
      this.editData.addr = this.user.addr;
      this.editData.city = this.user.city;
      this.editData.zip = this.user.zip;
      this.editData.country = this.user.country;
      this.editData.birthday = this.user.birthday;
      this.editData.tel = this.user.tel;
      // press info:
      if (this.userIsPress && this.user.company) {
        this.editDataCompany.pressId = this.user.company.pressId;
        this.editDataCompany.name = this.user.company.name;
        this.editDataCompany.phone = this.user.company.phone;
        this.editDataCompany.addr = this.user.company.addr;
        this.editDataCompany.city = this.user.company.city;
        this.editDataCompany.zip = this.user.company.zip;
        this.editDataCompany.country = this.user.company.country;
      }
      this.$refs["edit-user-modal"].show();
    },
    updateUserInfo() {
      if (!this.validate()) return;
      if (this.userIsPress) {
        this.editData.company = this.editDataCompany;
        // so that firebase does not complain
        if (!this.editData.birthday)
          this.editData.birthday = "";
      }
      var docRef = db.collection(C.COLLECTION.USERS).doc(this.userId);
      docRef
        .set(this.editData, { merge: true })
        .then(() => {
          this.$store
          this.$refs["edit-user-modal"].hide();
          toast.success("User profile updated.");
        })
        .catch((error) => {
          toast.error("Couldn't update user profile. " + error.message);
        });
    },
    updateIcon() { },
    addDeliveryAddress() {
      console.log(this.deliveryAddrChecked);
    },
    sendEmailVerification() {
      const emailTrigger = {
        type: C.EMAIL_TYPE.VERIFY,
        actionLink: C.CLUB_URL,
        email: auth.currentUser.email,
      };
      db.collection(C.COLLECTION.EMAILS).doc().set(emailTrigger).then(() => {
          toast.success("We have sent you a verification email. Please check your inbox.");
      })
        .catch(error => {
          toast.error("Sending verification email failed. " + error.message);
        });
    },
    reauthenticate() {
      var user = auth.currentUser;
      var credentials = auth.EmailAuthProvider.credential(
        user.email,
        this.currentPassword
      );

      user
        .reauthenticateWithCredential(credentials)
        .then(() => {
          //change email after re-authenticate
          this.updateEmail();
        })
        .catch((error) => {
          this.isSaving = false;
          toast.error(error.message);
        });
    },
    validateChangeEmail() {
      this.bluredPassword = true;
      if (!this.validPassword(this.currentPassword)) {
        this.validChangeEmail = false;
        return false;
      }

      this.bluredEmail = true;
      this.editEmailData.email = this.editEmailData.email.trim().toLowerCase();
      if (!this.validEmail(this.editEmailData.email)) {
        this.validChangeEmail = false;
        return false;
      }

      this.validChangeEmail = true;
      return true;
    },
    validate() {
      //Check first name
      this.bluredFirstName = true;
      if (!this.validName(this.editData.firstName)) {
        this.valid = false;
        return false;
      }
      //Check last name
      this.bluredLastName = true;
      if (!this.validName(this.editData.lastName)) {
        this.valid = false;
        return false;
      }

      this.bluredAddress = true;
      if (!this.validInput(this.editData.addr)) {
        this.valid = false;
        return false;
      }
      // this.bluredCity = true;
      // if (!this.validInput(this.editData.city)) {
      //   this.valid = false;
      //   return false;
      // }
      this.bluredZip = true;
      if (!this.validInput(this.editData.zip)) {
        this.valid = false;
        return false;
      }
      this.bluredTel = true;
      if (!this.validPhone(this.editData.tel)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validInput(input) {
      if (!input) {
        console.log("missing input");
        return false;
      }
      return input.trim().length > 0;
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    validName(name) {
      return name.trim().length > 2;
    },
    validPhone(phone) {
      return phone ? phone.trim().length > 5 : false;
    },
    validPassword(password) {
      return password.trim().length > 7;
    },
    accountTypeToStr(type) {
      if (!type)
        return "";

      return C.TYPE_INDEX[type];
    },
    accountStatusToStr(status) {
      if (status == undefined || status == null)
        return "";

      return C.STATUS.STATUS_INDEX[status];
    },
    hasActiveStatus(status) {
      if (
        status == 0 ||
        status == 1 ||
        status == 2 ||
        status == 10 ||
        status == 12
      )
        return true;
      else return false;
    },

    getModelName(watch) {
      console.log("GET MODEL NAME: " + watch.modelId);
      if (!watch)
        return "";

      const model = this.watchModels.get(watch.modleId);
      if (!model) {
        console.log("MODEL NOT FOUND in");
        console.log(this.watchModels);
        return "";
      }

      return model.name;
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    userType: {
      get() {
        return C.TYPE_INDEX[this.user.type];
      }
    },
    userLabel: {
      get() {
        return this.$store.getters.getUserLabel;
      },
    },
    userIsPress: {
      get() {
        return this.user.type === C.TYPE.PRESS;
      }
    },
    company: {
      get() {
        var company = {
          pressId: "",
          name: "",
          email: "",
          phone: "",
          addr: "",
          zip: "",
          city: "",
          country: "Luxembourg"
        };
        if (this.user.company) {
          company = this.user.company;
        }

        return company;
      }
    },
  },
  created() {
    //init

    //get id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.userId = this.$route.params.id;
    }

    //load user
    var docRef = db.collection(C.COLLECTION.USERS).doc(this.userId);
    // let self = this;
    docRef.onSnapshot((doc) => {
      var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      console.log("Remote userDoc update. source: ", source);
      this.$store.commit("setUser", doc.data());
    });

    //load watches
    console.log("loading watches for id: " + this.userId);
    this.loadData(this.userId);
  },
};
</script>

<style scoped></style>
